import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import Link from "../../common/Link"
import { keyframes } from "styled-components";
import {createRef, useEffect, useRef, useState} from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const AuditedSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 100px;
  /*width: calc(50% - 50px);*/
  /*max-width: 660px;*/
  /*margin-right: 390px;*/
  display: inline-block;
  vertical-align: top;
  text-align: left;
  /*margin-left: 25px;*/
  max-width: 1000px;
  
  > div {
    width: 50%;
  }
  
  @media screen and (max-width: 1080px) {
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
  }
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 40px);
    max-width: 440px;
    padding: 0 20px 100px;
    margin-left: 0;
    
    > div {
      width: 100%;
    }
  }
`

const Content = styled.div`
  width: 100%;
  padding-right: 25px;
  /*max-width: 990px;
  margin: 10px auto 0;*/
  
  @media screen and (max-width: 880px) {
    padding-right: 0;
  }
`

// markup
const AuditedSection = () => {

  return (
    <AuditedSectionWrapper>
      <div>
        <Content>
          <Title>
            Audited and secure.
          </Title>
          <Subtitle>
            Dolomite's core smart contracts have undergone four audits through their development - from Zeppelin Solutions, Bramah Systems, SECBIT Labs and Cyfrin - and Dolomite has had modules audited by Zokyo and Guardian. All production smart contracts have 100% line, statement, and branch test coverage.
          </Subtitle>
          <Link dest={'https://docs.dolomite.io/#/security?id=independent-audits'} text={'Read the audit reports'} />
        </Content>
      </div>
    </AuditedSectionWrapper>
  )
}

export default AuditedSection
